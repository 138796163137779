import React from "react";
import Img from "../assets/img3.jpg"; // Adjust the path as necessary

export const HowItWorks = () => {
  return (
    <div className="subSection how-it-works-section">
      <div className="container my-5">
        <div className="row align-items-center">
          <div className="col-lg-6  mt-3 mt-lg-0">
            <h2>How It Works</h2>
            {/* <p>
              Our remote NMS isn't just for patients; it's also here for
              pharmacists, understanding the challenges they face daily. It's a
              support system, seamlessly merging advanced and efficient
              solutions with the expertise of seasoned in house pharmacists.
              This service is designed to effortlessly integrate into your
              everyday routine. , giving pharmacists in store the freedom to
              tackle their many tasks without sacrificing the quality of care.
              It's not about piling on more work; it's about making your
              workload more manageable and creating a pharmacy environment
              that's both efficient and patient-focused.
            </p>
            <ul>
              <li>
                Our process for completing the NMS is meticulously designed for
                efficiency and reliability.
              </li>
              <li>
                Through secure remote access, we extract necessary data from
                pharmacy systems weekly at agreed-upon times, relieving
                pharmacies of administrative burdens.
              </li>
              <li>
                Our goal is to achieve NMS completion at a high standard,
                typically targeting 100% of the available slots per month,
                representing about 1% of monthly prescription items.
              </li>
              <li>
                Following data extraction, our experienced pharmacists conduct
                NMS interventions and follow-ups.
              </li>
              <li>
                Pharmacies then receive detailed NMS outcomes in an easily
                understandable Excel format at the end of each month.
              </li>
              <li>
                Our streamlined approach empowers pharmacies to prioritize other
                in-store tasks while we exclusively handle their NMS
                requirements.
              </li>
            </ul> */}
            {/* <p>
              Our remote NMS supports both patients and pharmacists by
              integrating advanced solutions with the expertise of seasoned
              pharmacists. Designed to blend seamlessly into daily routines, it
              frees pharmacists to focus on essential tasks without compromising
              care quality. This service makes workloads more manageable,
              creating an efficient, patient-focused pharmacy environment.
            </p> */}
            <ul>
              <li>
                We ensure efficiency and reliability by securely extracting
                necessary data from pharmacy systems weekly, relieving
                administrative burdens.
              </li>
              <li>
                Our goal is to achieve 100% NMS completion each month, targeting
                about 1% of monthly prescriptions.
              </li>
              <li>
                Experienced pharmacists conduct NMS interventions and
                follow-ups, providing detailed outcomes in an easy-to-read Excel
                format at month's end.
              </li>
              <li>
                Our streamlined process lets pharmacies prioritize in-store
                tasks while we handle their NMS needs.
              </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img src={Img} alt="HowItWorks" className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
