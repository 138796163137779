import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";

export const MyNavbar = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <Navbar bg="dark" variant="dark" expand="lg" fixed="top">
      <Container>
        <Navbar.Brand href="#">Pharma Services</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="mx-3" onClick={() => handleScroll("home")}>
              Home
            </Nav.Link>
            <Nav.Link className="mx-3" onClick={() => handleScroll("about")}>
              About
            </Nav.Link>
            <Nav.Link className="mx-3" onClick={() => handleScroll("contact")}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
