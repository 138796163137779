import React from "react";
import Img from "../assets/img1.jpg"; // Adjust the path as necessary

export const About = () => {
  return (
    <div className="subSection container my-5">
      <div className="row align-items-center">
        <div className="col-lg-6 ">
          <img src={Img} alt="About Us" className="img-fluid" />
        </div>
        <div className="col-lg-6 mt-3 mt-lg-0">
          <h2>About Us</h2>
          {/* <p>
            Pharma Services is more than just a remote NMS provider; we're your
            dedicated partner in streamlining pharmacy operations and enhancing
            patient care. With a team of experienced pharmacists at the helm, we
            understand the daily challenges faced by pharmacies and strive to
            provide comprehensive solutions that integrate seamlessly into your
            workflow.
          </p>
          <p>
            Our commitment goes beyond simply adding to your workload; we're
            here to make your pharmacy's operations more efficient and
            patient-focused. By leveraging advanced technology and industry
            expertise, we empower pharmacists to prioritize their essential
            tasks while ensuring that NMS targets are met with precision and
            compliance. Our mission is to drive positive outcomes for both
            pharmacies and patients. Through our tailored approach and
            unwavering dedication, we aim to transform the way pharmacies
            operate, delivering tangible results and measurable improvements in
            patient care.
          </p> */}
          <p>
            Pharma Services is your dedicated partner in streamlining pharmacy
            operations and enhancing patient care.
          </p>
          <p>
            Our experienced pharmacists provide comprehensive solutions that
            integrate seamlessly into your workflow making operations more
            efficient and patient-focused ensuring NMS targets are met with
            precision and compliance.
          </p>
          <p>
            Our mission is to drive positive outcomes for both pharmacies and
            patients, delivering tangible results and measurable improvements in
            patient care.
          </p>
        </div>
      </div>
    </div>
  );
};
