import React from "react";

export const ContactUs = () => {
  return (
    <div className="contact-us-section py-5">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h2 className="text-center mb-4">Contact Us</h2>
            <p className="text-center centered-text">
              Get in touch with Pharma Services to streamline your pharmacy
              operations with our remote NMS solutions. Our team is ready to
              assist and answer any questions you have. Reach out today to
              optimize your workflow and enhance patient outcomes.
            </p>
          </div>
        </div>
        <div className="row justify-content-center mt-4">
          <div className="col-md-6 text-center">
            <p>
              <strong>Phone:</strong> 07469214528 | 07989432969
            </p>
            <p>
              <strong>Email:</strong> info@pharma-services.co.uk
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
