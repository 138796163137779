import React from "react";

export const Hero = () => {
  const handleScroll = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="hero d-flex justify-content-start align-items-center p-5">
      <div className="text-left">
        <h1 className="display-4">Pharma Services</h1>
        <p>
          Empowering Pharmacies, Enhancing Care: Your Partner
          <br /> in Remote Pharmacy Services
        </p>
        <div className="mt-4">
          <button
            className="btn btn-light me-2"
            onClick={() => handleScroll("about")}
          >
            About Us
          </button>
          <button
            className="btn btn-secondary"
            onClick={() => handleScroll("contact")}
          >
            Contact Us
          </button>
        </div>
      </div>
    </div>
  );
};
