import React from "react";

export const Footer = () => {
  return (
    <footer className="footer bg-dark text-white py-3">
      <div className="container text-center">
        <p className="mb-0">
          &copy; 2023. All Rights Reserved By Pharma Service Ltd.
        </p>
      </div>
    </footer>
  );
};
