import "./App.css";
import { MyNavbar } from "./components/navbar";
import { Hero } from "./sections/hero";
import { About } from "./sections/about";
import { HowItWorks } from "./sections/howitworks";
import { WhyPharma } from "./sections/whypharma";
import { Footer } from "./components/footer";
import { ContactUs } from "./sections/contact";

function App() {
  return (
    <div className="App">
      <MyNavbar />
      <section id="home">
        <Hero />
      </section>
      <section id="about">
        <About />
      </section>
      <HowItWorks />
      <WhyPharma />
      <section id="contact">
        <ContactUs />
      </section>
      <Footer />
    </div>
  );
}

export default App;
