import React from "react";
import Img from "../assets/img5.jpg"; // Adjust the path as necessary

export const WhyPharma = () => {
  return (
    <div className="subSection container my-5">
      <div className="row align-items-center">
        <div className="col-lg-6">
          <img src={Img} alt="WhyPharma" className="img-fluid" />
        </div>
        <div className="col-lg-6 mt-3 mt-lg-0">
          <h2>Why Pharma Services</h2>
          {/* <p>
            At our company, we're committed to transforming pharmacy operations
            through our comprehensive remote NMS solutions. Our mission is
            simple: to assist pharmacies in boosting their NMS quality and
            numbers while ensuring strict compliance with data protection laws
            and regulations. By managing the NMS process remotely, we provide
            pharmacies with the freedom to focus on crucial in-store services
            such as dispensing and Pharmacy First initiatives. Meanwhile, our
            dedicated team is committed to delivering NMS’s interventions with a
            keen emphasis on optimising patient outcomes. We not only do this
            but also drive significant business growth by generating additional
            revenue.
          </p>
          <p>
            The Royal Pharmaceutical Society (RPS) estimates that pharmacist-led
            interventions, such as medication reviews and adherence support,
            could save the NHS in England up to £517 million annually by
            preventing medication-related hospital admissions and complications.
            This highlights the importance of hitting NMS targets not only for
            financial reasons but also for patient care. Additionally,
            pharmacists are increasingly overwhelmed with tasks, struggling to
            keep up with their daily responsibilities. Staffing shortages and
            limited support from healthcare authorities further exacerbate this
            issue, making it challenging for pharmacists to effectively meet all
            patient needs.
          </p> */}
          <p>
            The Royal Pharmaceutical Society (RPS) estimates that pharmacist-led
            interventions, such as medication reviews and adherence support,
            could save the NHS in England up to £517 million annually by
            preventing medication-related hospital admissions. This underscores
            the importance of meeting NMS targets for both financial and patient
            care reasons. However, pharmacists face overwhelming tasks, staffing
            shortages, and limited support, making it difficult to meet all
            patient needs effectively.
          </p>
          <p>
            At Pharma Services, we transform pharmacy operations with
            comprehensive remote NMS solutions. We help pharmacies boost NMS
            quality and numbers while ensuring data protection compliance. By
            managing NMS remotely, we free up pharmacies to focus on crucial
            in-store services. Our dedicated team optimizes patient outcomes
            through effective NMS interventions and drives business growth by
            generating additional revenue.
          </p>
        </div>
      </div>
    </div>
  );
};
